import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.FILTER_OIK]: {},
  [fields.FILTER_FUND]: {},
  [fields.FILTER_PERIOD_FROM]: "",
  [fields.FILTER_PERIOD_TO]: "",
  [fields.FILTER_CATEGORY_ERC_PROTOCOL]: [],
  [fields.FILTER_NUMBER_ACT]: "",
  [fields.FILTER_NUMBER_ERC_PROTOCOL]: "",
  [fields.FILTER_TITLE_ERC_PROTOCOL]: "",
});

export const currentFiltersTop = (): Record<string, unknown> => ({
  [fields.FILTER_KEYWORD]: null,
  [fields.FILTER_SEARCH_MORPH]: false,
  [fields.FILTER_SEARCH_NGRAMED]: false,
  [fields.FILTER_SEARCH_SYNONYM]: false,
  [fields.FILTER_SEARCH_FREQUENCY]: false,
});
