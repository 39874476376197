























































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ActsInfoModal from "@monorepo/inventory/src/views/ActsView/components/ActsInfoModal.vue";
import ToggleTableCellsContainer from "@monorepo/inventory/src/views/ActsView/components/ToggleTableCellsContainer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/actsView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/actsView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import useGetTableLibs from "./composables/useGetTableLibs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { breadcrumbs } from "@monorepo/inventory/src/views/ActsView/constants/breadcrumbs";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { actsView } from "@monorepo/inventory/src/store/modules/actsView";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ActsView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import { fields } from "@monorepo/utils/src/variables/projectsData/actsView/fields";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/actsView/filtersElements";
import {
  currentFilters as setCurrentFilters,
  currentFiltersTop as setCurrentFiltersTop,
} from "@monorepo/inventory/src/views/ActsView/utils/defaultCurrentFilters";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import FiltersTopNew from "@monorepo/uikit/src/components/common/FiltersTopNew.vue";
import TableNew from "@monorepo/inventory/src/views/ActsView/components/TableNew.vue";
import { ViewMode } from "@monorepo/utils/src/types/viewMode";
import PreviewModalNew from "@monorepo/inventory/src/components/newView/PreviewModalNew.vue";

const SECTION_NAME = "actsView";

export default defineComponent({
  name: "ActsView",
  components: {
    TableSettings,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    ExportButton,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    ActsInfoModal,
    ToggleTableCellsContainer,
    AutoRefreshButton,
    Footer,
    ExportFooterButton,
    ToggleDocumentView,
    FiltersTopNew,
    TableNew,
    PreviewModalNew,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      tableHeaders,
      pluralFormTitles: ["Акт передачи дел", "Акта передачи дел", "Актов передачи дел"],
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      setCurrentFiltersTop,
      ViewMode,
      isShowPdf: false,
      pages: [] as Record<string, unknown>[],
      uploadFileGuid: "",
      autocompleteFiltersKeys: [fields.FILTER_OIK, fields.FILTER_FUND, fields.FILTER_KEYWORD],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isOpenFilters",
      "data",
      "openedId",
      "isSelectAll",
      "infiniteId",
      "totalLength",
      "selectedIds",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "categoriesLibrary",
      "elasticValue",
      "viewMode",
    ]),
    currentDocumentView(): ViewMode {
      return this.viewMode?.typeDisplay || ViewMode.TABLE;
    },
    resultFiltersElement(): any {
      return !this.elasticValue ? this.filtersElements.slice(1) : this.filtersElements;
    },
    categories(): { title: string; value: string }[] {
      return convertToSelectItems(this.categoriesLibrary, "title", "code");
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        categories: this.categories,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.CASE_ACT_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setCells", "setAutorefresh"]),
    ...mapMutations(SECTION_NAME, ["refreshData", "clearFilters"]),
    changeIsShowPdf({ value, pages, uploadFileGuid }: { value: boolean; pages: Record<string, unknown>[]; uploadFileGuid: string }) {
      this.isShowPdf = value;
      this.pages = pages;
      this.uploadFileGuid = uploadFileGuid;
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showModal = true;
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.toggleModal(true);
          }
          break;
        case fields.NUMBER_INVENTORY:
          if (event[fields.NUMBER_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.moveByAuthorities(header, "/inventory/case-notes-list", {
              inventoryNumber: event[fields.NUMBER_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>] as string,
              isOpenModal: "1",
            });
          }
          break;
        case fields.TITLE_INVENTORY:
          if (event[fields.TITLE_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.moveByAuthorities(header, "/inventory/case-notes-list", {
              header: event[fields.TITLE_INVENTORY as keyof ReturnType<typeof convertApiItemToUi>] as string,
              isOpenModal: "1",
            });
          }
          break;
        case fields.ACT_CASE_NAME:
          if (event[fields.ACT_CASE_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.moveByAuthorities(header, "/inventory/case", {
              caseNumber: event[fields.ACT_CASE_NUMBER as keyof ReturnType<typeof convertApiItemToUi>] as string,
              isOpenModal: "1",
            });
          }
          break;
        case fields.OIK_NAME:
          if (event[fields.OIK_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.moveByAuthorities(header, "/dictionaries/oik", {
              name: event[fields.OIK_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string,
              isOpenModal: "1",
            });
          }
          break;
        case fields.FUND_NAME:
          if (event[fields.FUND_NAME as keyof ReturnType<typeof convertApiItemToUi>]) {
            this.moveByAuthorities(header, "/dictionaries/fund", {
              name: event[fields.FUND_NAME as keyof ReturnType<typeof convertApiItemToUi>] as string,
              isOpenModal: "1",
            });
          }
          break;
        default:
          break;
      }
    },
    toggleModal(value: boolean) {
      this.showModal = value;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    handleSetCells(value: Record<string, unknown>[]) {
      this.setCells(value.map((item) => item.value));
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const showModal = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const moveByAuthorities = useMoveByAuthorities(root);

    useInitTableStoreModule(root, SECTION_NAME, actsView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });
    useSetStartFilters(root, SECTION_NAME, ["id", "number"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showModal.value = true;
      }
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      showModal,
      sectionName,
      moveByAuthorities,
    };
  },
});
