
































import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { CaseTypes } from "@monorepo/inventory/src/constants/caseTypes";

export default defineComponent({
  name: "InventoryStatus",
  props: {
    event: {
      type: Object,
      default() {
        return {};
      },
    },
    hybrid: {
      type: Boolean,
      default: false,
    },
    showEads: {
      type: Boolean,
      default: true,
    },
    eadEntityName: {
      type: String,
      default: "документов",
    },
  },
  computed: {
    ...mapGetters("ERCProtocolsView", ["data"]),
    isPaper(): boolean {
      return this.event?.storageKind === CaseTypes.PAPER;
    },
    isHybrid(): boolean {
      return this.event?.storageKind === CaseTypes.HYBRID || this.hybrid;
    },
    count(): number {
      return this.isPaper ? this.paperCount : this.eadCount;
    },
    totalCount(): number {
      return this.isPaper ? this.paperTotalCount : this.eadTotalCount;
    },
    eadCount(): number {
      return this.event?.receivedEadCount || 0;
    },
    eadTotalCount(): number {
      return this.event?.totalEadCount || 0;
    },
    paperCount(): number {
      return this.event?.receivedPaperCount || 0;
    },
    paperTotalCount(): number {
      return this.event?.totalPaperCount || 0;
    },
    color(): (count: number, totalCount: number) => string {
      return (count: number, totalCount: number) => {
        if (!totalCount || !count) {
          return "#dfdfe4";
        } else {
          const alphaLayer = count / totalCount;
          return `rgba(0, 164, 89, ${alphaLayer === 1 ? 1 : alphaLayer < 0.4 ? 0.4 : alphaLayer > 0.7 ? 0.7 : alphaLayer})`;
        }
      };
    },
  },
});
