

















































import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";

export default defineComponent({
  name: "TreeNode",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCanCheck: {
      type: Boolean,
      default: false,
    },
    titleWidth: {
      type: String,
      default: "445px",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => [],
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("change", value);
      },
    },
  },
  data() {
    return {
      folder: `${environmentVariables.BASE_URL}assets/images/common/foleder.svg`,
    };
  },
});
